<template>
  <v-app id="inspire">
    <!--    &lt;!&ndash;通知栏&ndash;&gt;
        <v-snackbar
            timeout="2000"
            :value="snackbarShow"
            color="#F44336"
            absolute
            top
            v-model="snackbarShow"
        >登录成功！
        </v-snackbar>-->
    <!--侧边栏-->
    <v-navigation-drawer
        v-model="drawer"
        app
        dark
    >
      <v-card class="d-flex flex-row justify-space-around" height="60px">
        <v-icon large color="green accent-3">mdi-home-city-outline</v-icon>
        <div class="card_title">房屋安全管理系统</div>
      </v-card>
      <!--导航栏-->
      <v-list rounded>
        <v-list-item-group
            color="primary"
            v-model="selectedItem"
        >
          <v-list-item
              v-for="(item, i) in items"
              :key="i"
              @click="clickBar(item.path)"
          >
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>


    </v-navigation-drawer>


    <!--顶部 -->
    <v-app-bar app color="#FFFFFF" height="60px" >
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>首页</v-toolbar-title>
      <v-spacer></v-spacer>
      <!--      搜索框-->
      <div class="search_text mr-12">
        <v-text-field
            hide-details=""
            label="搜索"
            placeholder="请输入..."
            outlined
            dense
            rounded
            append-icon="mdi-magnify"
        ></v-text-field>
      </div>
      <!--通知-->
      <v-menu
          offset-y
          bottom
          :close-on-content-click=false
          max-width="400px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              icon
              v-bind="attrs"
              v-on="on"
          >
            <v-badge
                bordered
                dot
                color="red"
            >
              <v-icon>mdi-bell-ring-outline</v-icon>
            </v-badge>
          </v-btn>
        </template>
        <v-tabs
            class="msg_tabs"
        >
          <v-tab
              v-for="item in items2"
              :key="item"
          >
            {{ item }}
          </v-tab>
          <v-menu
              offset-y
              bottom
              :nudge-left="100"
              :nudge-top="20"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  class="msg_icon"
              >
                <v-icon>mdi-cog-outline</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                  v-for="item in setMsg"
                  :key="item"
                  @click="() => {}"
              >
                <v-list-item-title>{{item}}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-tab-item
          >
            <v-list three-line>
              <template v-for="(item, index) in readMsg">
                <v-divider
                    v-if="item.divider"
                    :key="index"
                    :inset="item.inset"
                ></v-divider>

                <v-list-item
                    v-else
                    :key="item.title"
                >
                  <v-list-item-avatar>
                    <v-img :src="item.avatar"></v-img>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title v-html="item.title"></v-list-item-title>
                    <v-list-item-subtitle v-html="item.subtitle"></v-list-item-subtitle>
                    <v-list-item-subtitle v-text="item.date"></v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            v-bind="attrs"
                            v-on="on"
                        >
                          mdi-email-check
                        </v-icon>
                      </template>
                      <span>标记为已读</span>
                    </v-tooltip>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-list>
          </v-tab-item>

          <v-tab-item
          >
            <v-list three-line>
              <template v-for="(item, index) in unReadMsg">
                <v-subheader
                    v-if="item.header"
                    :key="item.header"
                    v-text="item.header"
                ></v-subheader>

                <v-divider
                    v-else-if="item.divider"
                    :key="index"
                    :inset="item.inset"
                ></v-divider>

                <v-list-item
                    v-else
                    :key="item.title"
                >
                  <v-list-item-avatar>
                    <v-img :src="item.avatar"></v-img>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title v-html="item.title"></v-list-item-title>
                    <v-list-item-subtitle v-html="item.subtitle"></v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            v-bind="attrs"
                            v-on="on"
                        >
                          mdi-email-check
                        </v-icon>
                      </template>
                      <span>标记为未读</span>
                    </v-tooltip>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-list>
          </v-tab-item>
        </v-tabs>
      </v-menu>
      <!--登录账号-->
      <v-menu
          offset-y
          bottom
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              icon
              v-bind="attrs"
              v-on="on"
          >
            <v-icon>mdi-account-circle-outline</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
              v-for="(item,index) in userOption"
              :key="item"
              @click="userMsg(index)"
          >
            <v-list-item-title>{{item}}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <!--内容框-->
    <v-main class="bg_main">
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "Home",
  data(){
    return{
      selectedItem: 0,
      drawer:null,
      //功能栏
      items: [
        { text: '个人中心',path:'/PersonalCenter'},
        { text: '用户管理', path:'/UserManage' },
        { text: '安全指标管理',path:'/SafetyManagement'},
        { text: '房屋管理',path:'/HouseSafeInfo'},
        { text: '房屋安全展示', path:'/HousingDisplay'},
      ],
      //  登录用户选项
      userOption:['账号设置','退出登录'],
      tab: null,
      //通知
      items2: [
        '未读通知', '已读通知',
      ],
      setMsg:['全部已读','全部删除'],
      //通知内容
      readMsg: [
        {
          avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg',
          title: '安检员001',
          subtitle: '沙坪坝区—陈家桥街道已录入完成！',
          date:'2021.6.20'
        },
        {
          avatar: 'https://cdn.vuetifyjs.com/images/lists/2.jpg',
          title: '安检员002',
          subtitle: '新耀小区房屋安全信息已录入完成！',
          date:'2021.6.18'
        },
      ],
      unReadMsg: [
        {
          avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg',
          title: '安检员001',
          subtitle: '沙坪坝区—陈家桥街道已录入完成！',
          date:'2021.6.20'
        },
        {
          avatar: 'https://cdn.vuetifyjs.com/images/lists/2.jpg',
          title: '安检员002',
          subtitle: '新耀小区房屋安全信息已录入完成！',
          date:'2021.6.18'
        },
      ],
    }
  },
  methods:{
    userMsg(index){
      if(index === 0){
        this.$router.push('/PersonalCenter');
        this.selectedItem = 0;
      }
      if(index === 1){
        this.$router.push('/login');
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("username");
      }
    },
    clickBar(path){
      this.$router.push(path);
    }
  }
}
</script>

<style lang="less" scoped>
.bg_main{
  background-color: #F5F7F9;
}
.search_text{
  width: 300px;
}
.card_title{
  font-size: 20px;
  text-align: center;
  line-height: 60px;
  font-weight: bold;
}
.msg_tabs{
  position: relative;
  .msg_icon{
    position: absolute;
    right: 6%;
    top: 20%;
  }
}
</style>