var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"id":"inspire"}},[_c('v-navigation-drawer',{attrs:{"app":"","dark":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-card',{staticClass:"d-flex flex-row justify-space-around",attrs:{"height":"60px"}},[_c('v-icon',{attrs:{"large":"","color":"green accent-3"}},[_vm._v("mdi-home-city-outline")]),_c('div',{staticClass:"card_title"},[_vm._v("房屋安全管理系统")])],1),_c('v-list',{attrs:{"rounded":""}},[_c('v-list-item-group',{attrs:{"color":"primary"},model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}},_vm._l((_vm.items),function(item,i){return _c('v-list-item',{key:i,on:{"click":function($event){return _vm.clickBar(item.path)}}},[_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s(item.icon)}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.text)}})],1)],1)}),1)],1)],1),_c('v-app-bar',{attrs:{"app":"","color":"#FFFFFF","height":"60px"}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){_vm.drawer = !_vm.drawer}}}),_c('v-toolbar-title',[_vm._v("首页")]),_c('v-spacer'),_c('div',{staticClass:"search_text mr-12"},[_c('v-text-field',{attrs:{"hide-details":"","label":"搜索","placeholder":"请输入...","outlined":"","dense":"","rounded":"","append-icon":"mdi-magnify"}})],1),_c('v-menu',{attrs:{"offset-y":"","bottom":"","close-on-content-click":false,"max-width":"400px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-badge',{attrs:{"bordered":"","dot":"","color":"red"}},[_c('v-icon',[_vm._v("mdi-bell-ring-outline")])],1)],1)]}}])},[_c('v-tabs',{staticClass:"msg_tabs"},[_vm._l((_vm.items2),function(item){return _c('v-tab',{key:item},[_vm._v(" "+_vm._s(item)+" ")])}),_c('v-menu',{attrs:{"offset-y":"","bottom":"","nudge-left":100,"nudge-top":20},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"msg_icon",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-cog-outline")])],1)]}}])},[_c('v-list',_vm._l((_vm.setMsg),function(item){return _c('v-list-item',{key:item,on:{"click":function () {}}},[_c('v-list-item-title',[_vm._v(_vm._s(item))])],1)}),1)],1),_c('v-tab-item',[_c('v-list',{attrs:{"three-line":""}},[_vm._l((_vm.readMsg),function(item,index){return [(item.divider)?_c('v-divider',{key:index,attrs:{"inset":item.inset}}):_c('v-list-item',{key:item.title},[_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":item.avatar}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(item.title)}}),_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(item.subtitle)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(item.date)}})],1),_c('v-list-item-action',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-email-check ")])]}}],null,true)},[_c('span',[_vm._v("标记为已读")])])],1)],1)]})],2)],1),_c('v-tab-item',[_c('v-list',{attrs:{"three-line":""}},[_vm._l((_vm.unReadMsg),function(item,index){return [(item.header)?_c('v-subheader',{key:item.header,domProps:{"textContent":_vm._s(item.header)}}):(item.divider)?_c('v-divider',{key:index,attrs:{"inset":item.inset}}):_c('v-list-item',{key:item.title},[_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":item.avatar}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(item.title)}}),_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(item.subtitle)}})],1),_c('v-list-item-action',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-email-check ")])]}}],null,true)},[_c('span',[_vm._v("标记为未读")])])],1)],1)]})],2)],1)],2)],1),_c('v-menu',{attrs:{"offset-y":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account-circle-outline")])],1)]}}])},[_c('v-list',_vm._l((_vm.userOption),function(item,index){return _c('v-list-item',{key:item,on:{"click":function($event){return _vm.userMsg(index)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item))])],1)}),1)],1)],1),_c('v-main',{staticClass:"bg_main"},[_c('router-view')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }